import axios from 'axios'
import { $, each, truncate, isMobileDevice } from '../util'

const SocialWall = (() => {
  const setDimensions = () => {
    const blocks = $('.c-social-block')

    each(blocks, (i, block) => {
      const w = block.getBoundingClientRect().width
      block.style.height = `${w}px`
    })
  }

  const createTemplate = (posts, last = false, cta = '#') => {
    const template = `
    <div class="c-social-wall__row">
      <a href="${posts[0].permalink_url}" target="_blank" class="c-social-block js-social-link">
        <div class="c-social-block__image-mask">
          <div class="c-social-block__image" style="background-image: url(${posts[0].full_picture})"></div>
        </div>

        <div class="o-container-fluid">
          <div class="c-social-block__text-content">
            <div class="c-social-block__text">
              ${truncate(posts[0].message, 45)}
            </div>
          </div>

          <div class="c-social-block__text-icon">
            <div class="c-social-block__icon">
              <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.462 18.725H3.038V9.651H.754V6.523h2.284L3.035 4.68c0-2.551.696-4.104 3.722-4.104h2.52v3.128H7.702c-1.178 0-1.235.437-1.235 1.253l-.005 1.565h2.832L8.961 9.65H6.465l-.003 9.075z" fill="#fff"/>
              </svg>
            </div>
          </div>
        </div>
      </a>

      <a href="${posts[1].permalink_url}" target="_blank" class="c-social-block js-social-link">
        <div class="c-social-block__image-mask">
          <div class="c-social-block__image" style="background-image: url(${posts[1].full_picture})"></div>
        </div>

        <div class="o-container-fluid">
          <div class="c-social-block__text-content">
            <div class="c-social-block__text">
            ${truncate(posts[1].message, 45)}
            </div>
          </div>

          <div class="c-social-block__text-icon">
            <div class="c-social-block__icon">
              <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.462 18.725H3.038V9.651H.754V6.523h2.284L3.035 4.68c0-2.551.696-4.104 3.722-4.104h2.52v3.128H7.702c-1.178 0-1.235.437-1.235 1.253l-.005 1.565h2.832L8.961 9.65H6.465l-.003 9.075z" fill="#fff"/>
              </svg>
            </div>
          </div>
        </div>

      </a>

      ${last ? '' : `<div class="c-social-blank-col">
                      <div class="msc__cta msc__cta--no">
                        <div class="msc__cta-text">
                          <small>La nostra</small> Community

                            <a href="${cta}" class="msc__cta-button">
                              <div class="c-button-round c-button-round--orange">
                                <svg width="14" height="15" viewBox="0 0 14 15" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M13.281 8.487c.39-.376.39-1.01 0-1.415L6.921.466C6.532.061 5.9.036 5.51.412c-.39.376-.39 1.01 0 1.415l5.652 5.872L5.51 13.14c-.39.376-.39 1.01 0 1.415s1.022.43 1.413.053l6.359-6.121zM.965 8.31l11.61.442v-2L.964 6.31v2z" fill="#fff"/>
                                </svg>
                              </div>
                            </a>

                        </div>
                      </div>
                    </div>`}

    </div>

    <div class="c-social-wall__row c-social-wall__row--no-space">
      <div class="c-social-block__space">&nbsp;</div>

      <a href="${posts[2].permalink_url}" target="_blank" class="c-social-block js-social-link">
        <div class="c-social-block__image-mask">
          <div class="c-social-block__image" style="background-image: url(${posts[2].full_picture})"></div>
        </div>

        <div class="o-container-fluid">
          <div class="c-social-block__text-content">
            <div class="c-social-block__text">
            ${truncate(posts[2].message, 45)}
            </div>
          </div>

          <div class="c-social-block__text-icon">
            <div class="c-social-block__icon">
              <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.462 18.725H3.038V9.651H.754V6.523h2.284L3.035 4.68c0-2.551.696-4.104 3.722-4.104h2.52v3.128H7.702c-1.178 0-1.235.437-1.235 1.253l-.005 1.565h2.832L8.961 9.65H6.465l-.003 9.075z" fill="#fff"/>
              </svg>
            </div>
          </div>
        </div>
      </a>

      <a href="${posts[3].permalink_url}" target="_blank" class="c-social-block c-social-block--xl js-social-link">
        <div class="c-social-block__image-mask">
          <div class="c-social-block__image" style="background-image: url(${posts[3].full_picture})"></div>
        </div>

        <div class="o-container-fluid">
          <div class="c-social-block__text-content">
            <div class="c-social-block__text">
            ${truncate(posts[3].message, 45)}
            </div>
          </div>

          <div class="c-social-block__text-icon">
            <div class="c-social-block__icon">
              <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.462 18.725H3.038V9.651H.754V6.523h2.284L3.035 4.68c0-2.551.696-4.104 3.722-4.104h2.52v3.128H7.702c-1.178 0-1.235.437-1.235 1.253l-.005 1.565h2.832L8.961 9.65H6.465l-.003 9.075z" fill="#fff"/>
              </svg>
            </div>

          </div>

        </div>

      </a>

    </div>
    `

    return template
  }

  const loadPosts = (last = false) => {
    const walls = $('.js-social-wall')

    each(walls, (i, wall) => {
      const hashtag = wall.dataset.hashtag
      const endpoint = wall.dataset.endpoint
      const cta = wall.dataset.cta

      const rows = wall.querySelectorAll('.js-social-row')
      const loadMore = wall.querySelector('.js-load-more')

      axios.get(endpoint, {
        params: {
          page: last ? 'last' : '',
          hashtag,
        },
      })
      .then((response) => {
        const posts = response.data.posts

        const template = createTemplate(posts, last, cta)
        const templateDiv = document.createElement('div')
        templateDiv.innerHTML = template

        if (!last) {
          rows[0].appendChild(templateDiv)
        } else {
          rows[1].appendChild(templateDiv)
        }

        setDimensions()

        if (response.data.has_pages && !last) {
          loadMore.addEventListener('click', () => loadPosts(true))
        } else {
          loadMore.querySelector('.c-button__content').innerText = 'Scopri la nostra pagina Facebook'
          const loadMoreTemp = loadMore.cloneNode(true)
          loadMore.parentNode.replaceChild(loadMoreTemp, loadMore)
          const loadMoreNew = wall.querySelector('.js-load-more')

          loadMoreNew.addEventListener('click', function() {
            window.open(this.dataset.href, '_blank')
          })
        }

        listenLinks()
      })
      .catch(err => console.log(err))
    })
  }

  const listenLinks = () => {
    const links = $('.js-social-link')

    each(links, (i, link) => {
      link.addEventListener('click', (e) => {
        const url = link.getAttribute('href')

        if (isMobileDevice() === 'iOS') {
          e.preventDefault()
          const newUrl = `fb://profile/${url.split('/')[3]}`
          window.open(newUrl)
        } else if (isMobileDevice() === 'Android') {
          e.preventDefault()
          const newUrl = `intent://#Intent;package=com.facebook.katana;scheme=fb://profile/${url.split('/')[3]};end`
          window.open(newUrl)
        }
      })
    })
  }

  const init = () => {
    setDimensions()
    loadPosts()
    listenLinks()
  }

  return {
    init,
    setDimensions,
  }
})()

export default SocialWall
