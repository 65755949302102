import TweenMax, { TimelineMax, Power3 } from 'gsap'
import Flickity from 'flickity'
import axios from 'axios'
import { $, $1, each } from '../util'

const PlaylistManager = (() => {
  let isAnimating = false
  let player

  const openAnim = (firstVideoId) => {
    const openTl = new TimelineMax()
    if (!isAnimating) {
      const plModal = $1('.c-playlist-modal')
      const plModalBg = $1('.c-playlist-modal__bg')
      const plModalStripe = $1('.c-playlist-modal__stripe')
      const plModalClose = $1('.c-playlist-modal__close')
      const plmodalPlayer = $1('.c-playlist-modal__player')
      const playlistWrap = $1('.c-playlist-modal__playlist')

      isAnimating = true

      player.loadVideoById(firstVideoId, 0, 'large')

      openTl
        .set(plModal, {
          zIndex: 9999999,
          visibility: 'visible',
          opacity: 1,
        })
        .to(plModalBg, 0.6, {
          y: '100%',
          ease: Power3.easeOut,
        })
        .to(plModalStripe, 0.6, {
          y: '100%',
          x: '-50%',
          ease: Power3.easeOut,
        })
        .to(plmodalPlayer, 0.6, {
          y: '0',
          opacity: 1,
          ease: Power3.easeOut,
        })
        .to(playlistWrap, 0.6, {
          y: '0',
          opacity: 1,
          ease: Power3.easeOut,
        })
        .to(plModalClose, 0.4, {
          opacity: 1,
          onComplete: () => {
            isAnimating = false

            player.playVideo()
          },
        })
    }
  }

  const initPlaylistSlider = () => {
    const playlistSlider = $1('.c-playlist-modal__playlist-slider')
    const prev = $1('.js-playlist-prev')
    const next = $1('.js-playlist-next')

    const playlistInstance = new Flickity(playlistSlider, {
      cellSelector: '.c-playlist-modal__playlist-slide',
      cellAlign: 'left',
      contain: true,
      pageDots: false,
      prevNextButtons: false,
      freeScroll: true,
      wrapAround: true,
      draggable: false,
    })

    prev.addEventListener('click', function() {
      playlistInstance.previous(true, false)
    })

    next.addEventListener('click', function() {
      playlistInstance.next(true, false)
    })
  }

  const onPlaylistVideoClick = () => {
    const previews = $('.c-playlist-modal__playlist-video-preview')

    each(previews, (i, preview) => {
      preview.addEventListener('click', function() {
        player.loadVideoById(this.dataset.videoId, 0, 'large')
        player.playVideo()
      })
    })
  }

  const buildPlaylist = (videos) => {
    const playlistWrap = $1('.js-append-playlist')

    const slider = document.createElement('div')
    slider.className = 'c-playlist-modal__playlist-slider'
    const slidesFragment = document.createDocumentFragment()

    each(videos, (i, video) => {
      const slide = document.createElement('div')
      const videoPreview = document.createElement('div')
      const videoPreviewImage = document.createElement('div')
      const videoPreviewButton = document.createElement('div')
      const videoPreviewText = document.createElement('div')

      slide.className = 'c-playlist-modal__playlist-slide'
      videoPreview.className = 'c-playlist-modal__playlist-video-preview'
      videoPreviewImage.className = 'c-playlist-modal__playlist-video-preview-image'
      videoPreviewText.className = 'c-playlist-modal__playlist-video-preview-text'
      videoPreviewButton.className = 'c-playlist-modal__playlist-video-preview-button'

      videoPreviewImage.style.backgroundImage = `url(${video.thumbnails.medium.url})`
      videoPreviewText.innerText = video.title
      videoPreview.dataset.videoId = video.resourceId.videoId

      videoPreviewImage.appendChild(videoPreviewButton)

      videoPreview.appendChild(videoPreviewImage)
      videoPreview.appendChild(videoPreviewText)

      slide.appendChild(videoPreview)
      slidesFragment.appendChild(slide)
    })

    slider.appendChild(slidesFragment)
    playlistWrap.appendChild(slider)

    initPlaylistSlider()
    onPlaylistVideoClick()
  }

  const loadPlaylistVideo = (playlistId) => {
    const youtubeApiKey = 'AIzaSyAt2kKQfTeHR9BDzvEmAf4gf_VjxWx9-HE'

    document.body.classList.add('is-playlist-loading')

    axios.get('https://www.googleapis.com/youtube/v3/playlistItems', {
      params: {
        part: 'snippet',
        key: youtubeApiKey,
        maxResults: 20,
        playlistId,
      },
    })
    .then((response) => {
      const videos = response.data.items.map(v => v.snippet)

      document.body.classList.remove('is-playlist-loading')

      buildPlaylist(videos)
    })
    .catch((err) => {
      console.log(err)
      document.body.classList.remove('is-playlist-loading')
    })
  }

  const openModal = () => {
    const opener = $('.js-pl-modal-open')
    const stripe = $1('.c-playlist-modal__stripe')

    each(opener, (i, open) => {
      open.addEventListener('click', () => {
        openAnim(open.dataset.youtubeVideo)
        loadPlaylistVideo(open.dataset.youtubePlaylist)
        stripe.style.backgroundColor = `#${open.dataset.color}`
      })
    })
  }

  const closeAnim = () => {
    const closeTl = new TimelineMax()

    if (!isAnimating) {
      const plModal = $1('.c-playlist-modal')
      const plModalBg = $1('.c-playlist-modal__bg')
      const plModalStripe = $1('.c-playlist-modal__stripe')
      const plModalClose = $1('.c-playlist-modal__close')
      const plmodalPlayer = $1('.c-playlist-modal__player')
      const plModalSlider = $1('.c-playlist-modal__playlist-slider')
      const playlistWrap = $1('.c-playlist-modal__playlist')

      isAnimating = true

      player.pauseVideo()

      closeTl
        .to(plModalClose, 0.4, {
          opacity: 0,
        })
        .to(playlistWrap, 0.6, {
          y: '-33px',
          opacity: 0,
          ease: Power3.easeOut,
        })
        .to(plmodalPlayer, 0.6, {
          y: '-33px',
          opacity: 0,
          ease: Power3.easeOut,
        })
        .to(plModalStripe, 0.6, {
          y: '200%',
          x: '-50%',
          ease: Power3.easeOut,
        })
        .to(plModalBg, 0.6, {
          y: '200%',
          ease: Power3.easeOut,
        })
        .set(plModal, {
          zIndex: -1,
          visibility: 'hidden',
          opacity: 0,
          onComplete: () => {
            isAnimating = false

            TweenMax.set(plModalStripe, { y: '0%' })
            TweenMax.set(plModalBg, { y: '0%' })

            plModalSlider.parentNode.removeChild(plModalSlider)
          },
        })
    }
  }

  const closeModal = () => {
    const closer = $('.js-pl-modal-close')

    each(closer, (i, close) => {
      close.addEventListener('click', () => {
        closeAnim()
      })
    })
  }

  const buildAnim = () => {
    const modalPlayer = $1('.c-playlist-modal__player')
    const playlistWrap = $1('.c-playlist-modal__playlist')

    if (modalPlayer && playlistWrap) {
      TweenMax.set(modalPlayer, { opacity: 0, y: '-32px' })
      TweenMax.set(playlistWrap, { opacity: 0, y: '-32px' })
    }
  }

  const init = () => {
    buildAnim()

    openModal()
    closeModal()

    player = window.player
  }

  return {
    init,
  }
})()

export default PlaylistManager
