import Flickity from 'flickity'
import { TimelineMax, TweenMax, Power2 } from 'gsap'
import { $, $1, each } from '../util'

const CarouselManager = (() => {
  let isAnimating = false
  let isSolutionAnimating = false

  const animateChangeSlide = (slider) => {
    const titles = slider.querySelectorAll('.c-main-carousel__main-title-text')
    const titleNext = slider.querySelector('.c-main-carousel__main-title-text--next')
    const titleInitial = slider.querySelector('.c-main-carousel__main-title-text--initial')

    const subTitles = slider.querySelectorAll('.c-main-carousel__main-subtitle-text')
    const subtitleNext = slider.querySelector('.c-main-carousel__main-subtitle-text--next')
    const subtitleInitial = slider.querySelector('.c-main-carousel__main-subtitle-text--initial')

    if (titles.length) {
      const changeSlideTl = new TimelineMax()

      if (!isAnimating) {
        isAnimating = true

        changeSlideTl
          .staggerTo(titles, 0.5, {
            y: '-100%',
            ease: Power2.easeOut,
            onComplete: () => {
              TweenMax.set(titleNext, {
                y: '-0%',
                top: '0',
              })

              titleInitial.parentNode.removeChild(titleInitial)
              titleNext.classList.remove('c-main-carousel__main-title-text--next')
              titleNext.classList.add('c-main-carousel__main-title-text--initial')
            },
          }, 0.1)
          .staggerTo(subTitles, 0.5, {
            y: '-100%',
            ease: Power2.easeOut,
            onComplete: () => {
              TweenMax.set(subtitleNext, {
                y: '-0%',
                top: '0',
              })

              subtitleInitial.parentNode.removeChild(subtitleInitial)
              subtitleNext.classList.remove('c-main-carousel__main-subtitle-text--next')
              subtitleNext.classList.add('c-main-carousel__main-subtitle-text--initial')

              isAnimating = false
            },
          }, 0.1, '-=0.4')
      }
    }
  }

  const initProjectsCarousel = () => {
    const carousel = $('.js-projects-slider')

    each(carousel, (i, car) => {
      const prev = car.querySelector('.js-prev')
      const next = car.querySelector('.js-next')

      const projectsInstance = new Flickity(car, {
        cellSelector: '.c-projects-slide',
        cellAlign: 'center',
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        freeScroll: false,
        wrapAround: true,
        draggable: false,
      })

      prev.addEventListener('click', () => {
        if (!isAnimating) {
          projectsInstance.previous(true, false)
        }
      })

      next.addEventListener('click', () => {
        if (!isAnimating) {
          projectsInstance.next(true, false)
        }
      })

      function animateChange(slider) {

      }

      function generateNext(slide, slider) {
        const mainTitle = slider.querySelector('.c-projects-slider__main-title')
        const mainText = slider.querySelector('.c-projects-slider__main-text')
        const mainBg = slider.querySelector('.c-projects-slider__bg')
        const firstLayer = slider.querySelector('.c-projects-slider__bg-layer')

        if (mainTitle && mainText) {
          const title = slide.dataset.title
          const text = slide.dataset.text
          const bgColor = slide.dataset.bg

          const elTitle = document.createElement('div')
          elTitle.className = 'c-projects-slider__main-title c-projects-slider__main-title--next'
          elTitle.innerText = title

          const elText = document.createElement('div')
          elText.className = 'c-projects-slider__main-text c-projects-slider__main-text--next'
          elText.innerText = text

          const elBg = document.createElement('div')
          elBg.className = 'c-projects-slider__bg-layer c-projects-slider__bg-layer--next'
          elBg.style.backgroundColor = bgColor
          elBg.style.left = '100%'

          mainBg.appendChild(elBg)

          const mainContent = slider.querySelector('.c-projects-slider__content')

          const changeTl = new TimelineMax()

          TweenMax.set(elTitle, {
            opacity: 0,
            y: '-40px',
            x: '-40px',
          })

          TweenMax.set(elText, {
            opacity: 0,
            y: '-40px',
            x: '-40px',
          })

          changeTl
            .to(mainTitle, 0.4, {
              opacity: 0,
              y: '40px',
              x: '40px',
            })
            .to(mainText, 0.4, {
              opacity: 0,
              y: '40px',
              x: '40px',
              onComplete: () => {
                mainTitle.remove()
                mainText.remove()

                mainContent.appendChild(elTitle)
                mainContent.appendChild(elText)

                const newTitle = slider.querySelector('.c-projects-slider__main-title')
                const newText = slider.querySelector('.c-projects-slider__main-text')
                const newBg = slider.querySelector('.c-projects-slider__bg-layer--next')

                changeTl
                  .to(newTitle, 0.4, {
                    opacity: 1,
                    y: '0',
                    x: '0',
                  })
                  .to(newText, 0.4, {
                    opacity: 1,
                    y: '0',
                    x: '0',
                  }, '-=0.3')
                  .to(firstLayer, 0.4, {
                    x: '-100%',
                  }, '-=0.4')
                  .to(newBg, 0.4, {
                    x: '-100%',
                    onComplete: () => {
                      firstLayer.remove()
                      newBg.classList.remove('c-projects-slider__bg-layer--next')
                    },
                  }, '-=0.4')
              },
            }, '-=0.3')
        }
      }

      projectsInstance.on('change', (index) => {
        const nextSlide = projectsInstance.slides[index].cells[0].element

        generateNext(nextSlide, car)
      })
    })
  }

  const initMain = () => {
    const carousel = $('.js-main-carousel')

    function generateNext(slide, slider) {
      const mainTitle = slider.querySelector('.c-main-carousel__main-title')
      const mainSubtitle = slider.querySelector('.c-main-carousel__main-subtitle')

      if (mainTitle && mainSubtitle) {
        const title = slide.dataset.title
        const subtitle = slide.dataset.subtitle

        const elTitle = document.createElement('div')
        elTitle.className = 'c-main-carousel__main-title-text c-main-carousel__main-title-text--next'
        elTitle.innerText = title

        const elSubtitle = document.createElement('div')
        elSubtitle.className = 'c-main-carousel__main-subtitle-text c-main-carousel__main-subtitle-text--next'
        elSubtitle.innerText = subtitle

        mainTitle.appendChild(elTitle)
        mainSubtitle.appendChild(elSubtitle)
      }
    }

    each(carousel, (i, car) => {
      const prev = car.querySelector('.js-prev')
      const next = car.querySelector('.js-next')

      const mainImageSlider = car.querySelector('.c-main-carousel__main-image-slider')
      const slidesImages = car.querySelectorAll('.c-main-carousel__main-image-slide-bg')

      const mainInstance = new Flickity(car, {
        cellSelector: '.js-main-slide',
        cellAlign: 'center',
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        freeScroll: false,
        wrapAround: true,
        draggable: false,
      })

      const mainImagesInstance = new Flickity(mainImageSlider, {
        cellSelector: '.c-main-carousel__main-image-slide',
        cellAlign: 'center',
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        freeScroll: false,
        wrapAround: false,
        draggable: false,
      })

      prev.addEventListener('click', () => {
        if (!isAnimating) {
          mainInstance.previous(true, false)
          mainImagesInstance.previous(true, false)
        }
      })

      next.addEventListener('click', () => {
        if (!isAnimating) {
          mainInstance.next(true, false)
          mainImagesInstance.next(true, false)
        }
      })

      mainInstance.on('change', (index) => {
        const nextSlide = mainInstance.slides[index].cells[0].element

        generateNext(nextSlide, car)
        animateChangeSlide(car)
      })

      mainImagesInstance.on('scroll', () => {
        each(mainImagesInstance.slides, (l, slide) => {
          const relativeSlide = slidesImages[l]
          const x = (slide.target + mainImagesInstance.x) * (-1 / 3)
          relativeSlide.style.transform = `translateX(${x}px)`
        })
      })
    })
  }

  const initImageCarousel = () => {
    const carousel = $('.js-main-image-carousel')

    each(carousel, (i, car) => {
      const prev = car.querySelector('.js-prev')
      const next = car.querySelector('.js-next')

      const imageSlider = car.querySelector('.js-image-carousel')
      const slidesImages = car.querySelectorAll('.c-image-carousel__slide-image')

      const imageSliderInstance = new Flickity(imageSlider, {
        cellSelector: '.c-image-carousel__slide',
        cellAlign: 'center',
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        freeScroll: false,
        wrapAround: false,
        draggable: false,
      })

      prev.addEventListener('click', () => {
        if (!isAnimating) {
          imageSliderInstance.previous(true, false)
        }
      })

      next.addEventListener('click', () => {
        if (!isAnimating) {
          imageSliderInstance.next(true, false)
        }
      })

      imageSliderInstance.on('scroll', () => {
        each(imageSliderInstance.slides, (l, slide) => {
          const relativeSlide = slidesImages[l]
          const x = (slide.target + imageSliderInstance.x) * (-1 / 3)
          relativeSlide.style.transform = `translateX(${x}px)`
        })
      })
    })
  }

  const initSimpleCarousel = () => {
    const simpleCarousel = $('.c-simple-carousel')

    each(simpleCarousel, (i, simple) => {
      const prev = simple.querySelector('.js-prev')
      const next = simple.querySelector('.js-next')

      const simpleInstance = new Flickity(simple, {
        cellSelector: '.c-simple-carousel__slide',
        cellAlign: 'center',
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        freeScroll: false,
        wrapAround: true,
        draggable: true,
      })

      prev.addEventListener('click', () => {
        simpleInstance.previous(true, false)
      })

      next.addEventListener('click', () => {
        simpleInstance.next(true, false)
      })
    })
  }

  const initRoundCarousel = () => {
    const roundCarousel = $('.c-round-slider')

    each(roundCarousel, (i, round) => {
      const prev = round.querySelector('.js-prev')
      const next = round.querySelector('.js-next')

      const roundInstance = new Flickity(round, {
        cellSelector: '.c-round-slide',
        cellAlign: 'center',
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        freeScroll: false,
        wrapAround: true,
        draggable: true,
      })

      prev.addEventListener('click', () => {
        roundInstance.previous(true, false)
      })

      next.addEventListener('click', () => {
        roundInstance.next(true, false)
      })
    })
  }

  const initHeroCarousel = () => {
    const heroCarousel = $('.c-hero-carousel')

    each(heroCarousel, (i, heroC) => {
      const prev = heroC.querySelector('.js-prev')
      const next = heroC.querySelector('.js-next')

      const options = {
        cellSelector: '.c-hero-carousel__slide',
        cellAlign: 'center',
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        freeScroll: false,
        wrapAround: true,
        draggable: false,
        autoPlay: 3000,
        pauseAutoPlayOnHover: true,
      }

      // enable prev/next buttons at 768px
      if (window.matchMedia('screen and (max-width: 1023px)').matches) {
        options.draggable = true
      } else {
        options.draggable = false
      }

      const heroInstance = new Flickity(heroC, options)

      const heroFloatSl = heroC.parentNode.parentNode.querySelector('.c-hero-float__slider')

      const heroFloatInstance = new Flickity(heroFloatSl, {
        cellSelector: '.c-hero-float__slide',
        cellAlign: 'center',
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        freeScroll: false,
        wrapAround: true,
        draggable: false,
      })

      prev.addEventListener('click', () => {
        heroInstance.previous(true, false)
      })

      next.addEventListener('click', () => {
        heroInstance.next(true, false)
      })

      heroInstance.on('change', function(index) {
        heroFloatInstance.select(index, true, false)
      })
    })
  }

  const animateSolutionChange = (nextSlide) => {
    if (!isSolutionAnimating) {
      isSolutionAnimating = true

      const bgColor = nextSlide.dataset.bgColor
      const textColor = nextSlide.dataset.textColor
      const slideTitle = nextSlide.dataset.title
      const slideSubtitle = nextSlide.dataset.subtitle
      const slideText = nextSlide.dataset.text

      const mask = $1('.c-solution-block__bg-mask')
      const el = document.createElement('div')
      el.className = 'c-solution-block__bg-el'
      el.style.backgroundColor = bgColor
      mask.appendChild(el)

      const title = document.createElement('div')
      title.className = 'c-solution-block__title'
      title.style.color = textColor
      title.innerText = slideTitle

      const subtitle = document.createElement('div')
      subtitle.className = 'c-solution-block__subtitle'
      subtitle.style.color = textColor
      subtitle.innerText = slideSubtitle

      const text = document.createElement('div')
      text.className = 'c-solution-block__text'
      text.innerHTML = slideText

      TweenMax.set(title, { opacity: 0, y: '-50px', x: '-40px' })
      TweenMax.set(subtitle, { opacity: 0, y: '-50px', x: '-40px' })
      TweenMax.set(text, { opacity: 0, y: '-50px', x: '-40px' })

      const bgs = $('.c-solution-block__bg-el')
      const animateSolutionTl = new TimelineMax()

      animateSolutionTl
        .to(bgs, 0.5, {
          x: '-100%',
          ease: Power2.easeOut,
          onComplete: () => {
            isSolutionAnimating = false

            $('.c-solution-block__bg-el')[0].remove()
            TweenMax.set($('.c-solution-block__bg-el')[0], {
              x: '0%',
            })

            const oldTitle = $1('.c-solution-block__title')
            const oldSubtitle = $1('.c-solution-block__subtitle')
            const oldText = $1('.c-solution-block__text')
            const contentZone = $1('.c-solution-block__content-zone')

            animateSolutionTl
              .to(oldTitle, 0.4, {
                opacity: 0,
                y: '50px',
                x: '40px',
                ease: Power2.easeOut,
              })
              .to(oldSubtitle, 0.4, {
                opacity: 0,
                y: '50px',
                x: '40px',
                ease: Power2.easeOut,
              }, '-=0.35')
              .to(oldText, 0.4, {
                opacity: 0,
                y: '50px',
                x: '40px',
                ease: Power2.easeOut,
                onComplete: () => {
                  oldTitle.remove()
                  oldSubtitle.remove()
                  oldText.remove()

                  contentZone.appendChild(title)
                  contentZone.appendChild(subtitle)
                  contentZone.appendChild(text)

                  const newTitle = $1('.c-solution-block__title')
                  const newSubtitle = $1('.c-solution-block__subtitle')
                  const newText = $1('.c-solution-block__text')

                  animateSolutionTl
                    .to(newTitle, 0.4, {
                      opacity: 1,
                      y: '0',
                      x: '0',
                      ease: Power2.easeOut,
                    })
                    .to(newSubtitle, 0.4, {
                      opacity: 1,
                      y: '0',
                      x: '0',
                      ease: Power2.easeOut,
                    }, '-=0.35')
                    .to(newText, 0.4, {
                      opacity: 1,
                      y: '0',
                      x: '0',
                      ease: Power2.easeOut,
                    }, '-=0.35')
                },
              }, '-=0.3')
          },
        })
    }
  }

  const initSolutionSlider = () => {
    const solutionSlider = $1('.c-solution-block__slider')

    if (solutionSlider) {
      const solutionImageSlider = solutionSlider.querySelector('.c-solution-block__image-slider')
      const firstSlide = $('.c-solution-block__slide')[0]
      const title = $1('.c-solution-block__title')
      const subtitle = $1('.c-solution-block__subtitle')
      const firstBgColor = firstSlide.dataset.bgColor
      const firstTextColor = firstSlide.dataset.textColor

      const prev = solutionSlider.querySelector('.js-prev')
      const next = solutionSlider.querySelector('.js-next')

      const bgEl = solutionSlider.querySelector('.c-solution-block__bg-el')
      bgEl.style.backgroundColor = firstBgColor
      title.style.color = firstTextColor
      subtitle.style.color = firstTextColor

      const solutionSliderInstance = new Flickity(solutionSlider, {
        cellSelector: '.c-solution-block__slide',
        cellAlign: 'center',
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        freeScroll: false,
        wrapAround: true,
        draggable: false,
      })

      const solutionImageSliderInstance = new Flickity(solutionImageSlider, {
        cellSelector: '.c-solution-block__image-slide',
        cellAlign: 'center',
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        freeScroll: false,
        wrapAround: true,
        draggable: false,
      })

      if (prev && next) {
        prev.addEventListener('click', () => {
          if (!isSolutionAnimating) {
            solutionSliderInstance.previous(true, false)
            solutionImageSliderInstance.previous(true, false)
          }
        })

        next.addEventListener('click', () => {
          if (!isSolutionAnimating) {
            solutionSliderInstance.next(true, false)
            solutionImageSliderInstance.next(true, false)
          }
        })
      }

      solutionSliderInstance.on('change', (index) => {
        const nextSlide = solutionSliderInstance.slides[index].cells[0].element

        // generateNext(nextSlide, car)
        animateSolutionChange(nextSlide)
      })
    }
  }

  const init = () => {
    initMain()
    initImageCarousel()
    initSimpleCarousel()
    initRoundCarousel()
    initHeroCarousel()
    initSolutionSlider()
    initProjectsCarousel()
  }

  return {
    init,
  }
})()

export default CarouselManager
