import { TimelineMax, TweenMax, Power3 } from 'gsap'
import { $, $1, each } from '../util'

const EnergySaving = (() => {
  const saturationColors = () => {
    const bubble = $1('.c-nav__action-bubble')
    const bodyClass = $1('body')

    bubble.addEventListener('click', function () { 
      this.classList.toggle('c-nav__action-bubble--active')
      bodyClass.classList.toggle('c-saturation__color')
    })
  }
  const init = () => {
    saturationColors()
  }

  return {
    init,
  }
})()

export default EnergySaving
