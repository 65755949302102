import YouTubePlayer from 'youtube-player'
import { debounce, consoleUtil } from './util'
import CarouselManager from './components/CarouselManager'
import NavbarManager from './components/NavbarManager'
import Tabs from './components/Tabs'
// import SocialWall from './components/SocialWall'
import Timeline from './components/Timeline'
import Organization from './components/Organization'
import Assistance from './components/Assistance'
import PlaylistManager from './components/PlaylistManager'
import ContactsManager from './components/ContactsManager'
import RouterManager from './components/RouterManager'
import QuizManager from './components/QuizManager'
import FullscreenVideo from './components/FullscreenVideo'
import Curiosities from './components/Curiosities'
import JobRequests from './components/JobRequests'
import CookieManager from './components/CookieManager'
import AnimationManager from './components/AnimationManager'
import NewsletterManager from './components/NewsletterManager'
import EnergySaving from './components/EnergySaving'

window.player = YouTubePlayer('player', {
  rel: 0,
})

document.addEventListener('DOMContentLoaded', () => {
  document.documentElement.className = 'js'

  RouterManager.init()
  CarouselManager.init()
  NavbarManager.init()
  // SocialWall.init()
  Assistance.init()
  Organization.init()
  Timeline.init()
  Tabs.init()
  ContactsManager.init()
  NewsletterManager.init()
  QuizManager.init()
  JobRequests.init()
  Curiosities.init()
  PlaylistManager.init()
  CookieManager.init()
  FullscreenVideo.init()
  AnimationManager.init()
  EnergySaving.init()
  consoleUtil()
})

window.addEventListener('resize', debounce(() => {
  // SocialWall.setDimensions()
  ContactsManager.setDimensions()
  AnimationManager.manageProjectBlocks()
}, 250))

window.addEventListener('load', () => {
  AnimationManager.loadAnimation()
})

