import TweenMax, { TimelineMax, Power3 } from 'gsap'
import { $1, $, each } from '../util'

const Assistance = (() => {
  let isAnimating = false

  const openAnim = () => {
    const openTl = new TimelineMax()
    if (!isAnimating) {
      const assModal = $1('.c-assistance-modal')
      const assModalBg = $1('.c-assistance-modal__bg')
      const assModalBgLayer = $1('.c-assistance-modal__bg-layer')
      const assModalIcon = $1('.c-assistance-modal__icon')
      const assModalContent = $1('.c-assistance-modal__content')
      const assModalClose = $1('.c-assistance-modal__close-container')

      isAnimating = true

      openTl
        .set(assModal, {
          zIndex: 9999999,
          visibility: 'visible',
          opacity: 1,
        })
        .to(assModalBgLayer, 0.6, {
          y: '-100%',
          ease: Power3.easeOut,
        })
        .to(assModalBg, 0.6, {
          y: '-100%',
          ease: Power3.easeOut,
        }, '-=0.3')
        .to(assModalContent, 0.6, {
          opacity: 1,
          y: '-50%',
        })
        .to(assModalIcon, 0.6, {
          opacity: 1,
          x: '0%',
          y: '0%',
        }, '-=0.4')
        .to(assModalClose, 0.4, {
          opacity: 1,
          onComplete: () => {
            isAnimating = false
          },
        })
    }
  }

  const openAssistance = () => {
    const opener = $('.js-assistance-open')

    each(opener, (i, open) => {
      open.addEventListener('click', () => {
        openAnim()
      })
    })
  }

  const closeAnim = () => {
    const closeTl = new TimelineMax()
    if (!isAnimating) {
      const assModal = $1('.c-assistance-modal')
      const assModalBg = $1('.c-assistance-modal__bg')
      const assModalBgLayer = $1('.c-assistance-modal__bg-layer')
      const assModalIcon = $1('.c-assistance-modal__icon')
      const assModalContent = $1('.c-assistance-modal__content')
      const assModalClose = $1('.c-assistance-modal__close-container')

      isAnimating = true

      closeTl
        .to(assModalClose, 0.4, {
          opacity: 0,
        })
        .to(assModalIcon, 0.6, {
          opacity: 0,
          x: '-14px',
          y: '-14px',
        })
        .to(assModalContent, 0.6, {
          opacity: 0,
          y: '-55%',
        }, '-=0.4')
        .to(assModalBg, 0.6, {
          y: '-200%',
          ease: Power3.easeOut,
        })
        .to(assModalBgLayer, 0.6, {
          y: '-200%',
          ease: Power3.easeOut,
        }, '-=0.4')
        .set(assModal, {
          zIndex: -1,
          visibility: 'hidden',
          opacity: 0,
          onComplete: () => {
            isAnimating = false

            TweenMax.set(assModalBg, { y: '0%' })
            TweenMax.set(assModalBgLayer, { y: '0%' })
          },
        })
    }
  }

  const closeAssistance = () => {
    const closer = $('.js-assistance-close')

    each(closer, (i, close) => {
      close.addEventListener('click', () => {
        closeAnim()
      })
    })
  }

  const init = () => {
    openAssistance()
    closeAssistance()
  }

  return {
    init,
  }
})()

export default Assistance
