import { TimelineMax, Power2 } from 'gsap'
import { $, each } from '../util'

const Tabs = (() => {
  let isAnimating = false

  const animateTabChange = (index, tabGroup) => {
    if (!isAnimating) {
      isAnimating = true

      const allTabs = tabGroup.querySelectorAll('.c-tab')
      const selectedTab = tabGroup.querySelector(`.c-tab[data-tab="${index}"]`)

      const tabTl = new TimelineMax()

      tabTl
        .to(allTabs, 0.5, {
          ease: Power2.easeOut,
          opacity: '0',
          y: '-20px',
          onComplete: () => {
            each(allTabs, (i, tabs) => {
              tabs.classList.remove('is-active')
            })

            selectedTab.classList.add('is-active')
          },
        })
        .to(selectedTab, 0.5, {
          ease: Power2.easeOut,
          opacity: '1',
          y: '0',
          onComplete: () => {
            isAnimating = false
          },
        })
    }
  }

  const initEvents = () => {
    const tabs = $('.c-tabs')

    each(tabs, (i, tab) => {
      const tabItem = tab.querySelectorAll('.c-tabs__item')

      each(tabItem, (l, item) => {
        item.addEventListener('click', function() {
          if (!this.classList.contains('is-active') && !isAnimating) {
            each(tabItem, (k, tbItem) => tbItem.classList.remove('is-active'))
            this.classList.add('is-active')

            animateTabChange(this.dataset.tab, tab)
          }
        })
      })
    })
  }

  const init = () => {
    initEvents()
  }

  return {
    init,
  }
})()

export default Tabs
