import Flickity from 'flickity'
import { $, each } from '../util'

const Timeline = (() => {
  const initTl = () => {
    const timeline = $('.c-timeline')

    each(timeline, (i, tl) => {
      const prev = tl.querySelector('.js-tl-prev')
      const next = tl.querySelector('.js-tl-next')

      const tlInstance = new Flickity(tl, {
        cellSelector: '.c-timeline__slide',
        cellAlign: 'left',
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        freeScroll: true,
        wrapAround: true,
        draggable: true,
      })

      prev.addEventListener('click', function() {
        tlInstance.previous(true, false)
      })

      next.addEventListener('click', function() {
        tlInstance.next(true, false)
      })
    })
  }

  const init = () => {
    initTl()
  }

  return {
    init,
  }
})()

export default Timeline
