import { TimelineMax, TweenMax, Power3 } from 'gsap'
import axios from 'axios'
import { $, $1, each } from '../util'

const JobRequests = (() => {
  let isAnimating
  let isSending

  let nameValue
  let surnameValue
  let emailValue
  let messageValue

  const openAnim = () => {
    const openTl = new TimelineMax()

    if (!isAnimating) {
      const jobsModal = $1('.c-jobs-modal')
      const jobsModalBg = $1('.c-jobs-modal__bg')
      const jobsModalBgLayer = $1('.c-jobs-modal__bg-layer')
      const jobsModalClose = $('.c-jobs-modal__close-container')
      const jobsModalContent = $1('.c-jobs-modal__content')

      isAnimating = true

      openTl
        .set(jobsModal, {
          zIndex: 9999999,
          visibility: 'visible',
          opacity: 1,
        })
        .to(jobsModalBgLayer, 0.6, {
          y: '-100%',
          ease: Power3.easeOut,
        })
        .to(jobsModalBg, 0.6, {
          y: '-100%',
          ease: Power3.easeOut,
        }, '-=0.3')
        .to(jobsModalContent, 0.6, {
          opacity: 1,
        }, '-=0.3')
        .to(jobsModalClose, 0.4, {
          opacity: 1,
          onComplete: () => {
            isAnimating = false
          },
        })
    }
  }

  const openJobs = () => {
    const opener = $('.js-open-jobs')

    each(opener, (i, open) => {
      open.addEventListener('click', () => {
        openAnim()
      })
    })
  }

  const closeAnim = () => {
    const closeTl = new TimelineMax()

    if (!isAnimating) {
      const jobsModal = $1('.c-jobs-modal')
      const jobsModalBg = $1('.c-jobs-modal__bg')
      const jobsModalBgLayer = $1('.c-jobs-modal__bg-layer')
      const jobsModalClose = $('.c-jobs-modal__close-container')
      const jobsModalContent = $1('.c-jobs-modal__content')

      isAnimating = true

      closeTl
        .to(jobsModalClose, 0.4, {
          opacity: 0,
        })
        .to(jobsModalContent, 0.4, {
          opacity: 0,
        })
        .to(jobsModalBg, 0.6, {
          y: '-200%',
          ease: Power3.easeOut,
        })
        .to(jobsModalBgLayer, 0.6, {
          y: '-200%',
          ease: Power3.easeOut,
        }, '-=0.4')
        .set(jobsModal, {
          zIndex: -1,
          visibility: 'hidden',
          opacity: 0,
          onComplete: () => {
            isAnimating = false

            TweenMax.set(jobsModalBg, { y: '0%' })
            TweenMax.set(jobsModalBgLayer, { y: '0%' })
          },
        })
    }
  }

  const closeJobs = () => {
    const closer = $('.js-close-jobs')

    each(closer, (i, close) => {
      close.addEventListener('click', () => {
        closeAnim()
      })
    })
  }

  const onInputFile = () => {
    const inputs = $('.c-jobs-modal__input-file')
    each(inputs, (i, input) => {
      const label = input.nextElementSibling
      const labelVal = label.innerHTML

      input.addEventListener('change', function(e) {
        let fileName = ''

        console.log(this.files[0].size)
        if (this.files[0].size > 5000000) {
          console.log('too big')
        } else {
          if (this.files && this.files.length > 1) {
            fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length)
          } else {
            fileName = e.target.value.split('\\').pop()
          }

          if (fileName) {
            label.querySelector('span').innerHTML = fileName
          } else {
            label.innerHTML = labelVal
          }
        }
      })
    })
  }

  const permitSubmit = () => {
    const check = $1('.js-jobs-check')

    if (check) {
      const submitBtn = $1('.js-jobs-submit')

      if (check.checked) {
        submitBtn.removeAttribute('disabled')
      } else {
        submitBtn.setAttribute('disabled', true)
      }

      check.addEventListener('click', () => {
        setTimeout(() => {
          if (check.checked) {
            submitBtn.removeAttribute('disabled')
          } else {
            submitBtn.setAttribute('disabled', true)
          }
        }, 200)
      })
    }
  }

  const reset = (timeout = 3000) => {
    setTimeout(() => {
      document.body.classList.remove('is-jobs-success', 'is-jobs-error')
    }, timeout)
  }

  const manageSubmit = () => {
    const form = $1('.js-jobs-form')

    if (form) {
      const cvFileInput = document.getElementById('jobs-file')
      const jobsCheck = $1('.js-jobs-check')

      form.addEventListener('submit', (e) => {
        e.preventDefault()

        nameValue = form.querySelector('[name="name"]').value
        surnameValue = form.querySelector('[name="surname"]').value
        emailValue = form.querySelector('[name="email"]').value
        messageValue = form.querySelector('[name="message"]').value

        const endpoint = form.dataset.endpoint
        const data = new FormData()

        data.append('name', nameValue)
        data.append('surname', surnameValue)
        data.append('email', emailValue)
        data.append('cv', cvFileInput.files[0])
        data.append('message', messageValue)

        const responseTitle = $1('.js-jobs-response-title')
        const responseText = $1('.js-jobs-response-text')

        if (!isSending && jobsCheck.checked) {
          isSending = true
          document.body.classList.add('is-jobs-loading')

          axios({
            method: 'post',
            url: endpoint,
            data,
          })
          .then((response) => {
            console.log(response.data)
            isSending = false

            document.body.classList.remove('is-jobs-loading')

            if (response.data.result) {
              document.body.classList.add('is-jobs-success')

              responseTitle.innerText = 'Richiesta inviata'
              responseText.innerText = 'La tua candidatura è stata inviata con successo.'
            } else {
              document.body.classList.add('is-jobs-error')

              responseTitle.innerText = 'Errore'
              responseText.innerText = response.data.error
            }

            reset()
          })
          .catch((err) => {
            isSending = false
            console.log(err)

            reset(5600)

            responseTitle.innerText = 'Errore di connessione.'
            responseText.innerText = 'Riprova più tardi.'
          })
        }
      })
    }
  }

  const init = () => {
    openJobs()
    closeJobs()
    onInputFile()
    manageSubmit()
    permitSubmit()
  }

  return {
    init,
  }
})()

export default JobRequests
