import { TimelineMax } from 'gsap'
import { $1, $, each } from '../util'

const Curiosities = (() => {
  let isAnimating = false

  const openAnim = () => {
    const openTl = new TimelineMax()
    if (!isAnimating) {
      const cModal = $1('.c-curiosity-modal')
      const cModalBody = $1('.c-curiosity-modal__body')
      const cModalLayer = $1('.c-curiosity-modal__layer')
      const cModalCloseContainer = $1('.c-curiosity-modal__close-container')
      const cModalContent = $1('.c-curiosity-modal__content')

      isAnimating = true

      openTl
        .set(cModal, {
          zIndex: 1000,
          visibility: 'visible',
          opacity: 1,
        })
        .to(cModalBody, 0.3, {
          opacity: 1,
        })
        .to(cModalLayer, 0.6, {
          opacity: 1,
        }, '-=0.2')
        .to(cModalCloseContainer, 0.4, {
          opacity: 1,
        }, '-=0.6')
        .to(cModalContent, 0.4, {
          opacity: 1,
          onComplete: () => {
            isAnimating = false
          },
        }, '-=0.4')
    }
  }

  const openCuriosity = () => {
    const opener = $('.js-curiosity-open')
    const text = $1('.c-curiosity-modal__text')

    each(opener, (i, open) => {
      open.addEventListener('click', () => {
        text.innerText = open.dataset.text

        openAnim()
      })
    })
  }

  const closeAnim = () => {
    const closeTl = new TimelineMax()
    if (!isAnimating) {
      const cModal = $1('.c-curiosity-modal')
      const cModalBody = $1('.c-curiosity-modal__body')
      const cModalLayer = $1('.c-curiosity-modal__layer')
      const cModalCloseContainer = $1('.c-curiosity-modal__close-container')
      const cModalContent = $1('.c-curiosity-modal__content')
      const text = $1('.c-curiosity-modal__text')

      isAnimating = true

      closeTl
        .to(cModalCloseContainer, 0.4, {
          opacity: 0,
        })
        .to(cModalContent, 0.4, {
          opacity: 0,
        }, '-=0.3')
        .to(cModalBody, 0.4, {
          opacity: 0,
        }, '-=0.3')
        .to(cModalLayer, 0.6, {
          opacity: 0,
          onComplete: () => {
            isAnimating = false
            text.innerText = ''
          },
        }, '-=0.3')
        .set(cModal, {
          zIndex: -1,
          visibility: 'hidden',
          opacity: 0,
        })
    }
  }

  const closeCuriosity = () => {
    const closer = $('.js-curiosity-close')

    each(closer, (i, close) => {
      close.addEventListener('click', () => {
        closeAnim()
      })
    })
  }

  const init = () => {
    openCuriosity()
    closeCuriosity()
  }

  return {
    init,
  }
})()

export default Curiosities
