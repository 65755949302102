export function debounce(func, wait, immediate) {
  var timeout
  return function() {
    var context = this
    var args = arguments
    var later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export function throttle(callback, wait, context = this) {
  let timeout = null
  let callbackArgs = null

  const later = () => {
    callback.apply(context, callbackArgs)
    timeout = null
  }

  return function() {
    if (!timeout) {
      callbackArgs = arguments
      timeout = setTimeout(later, wait)
    }
  }
}

export function each(array, callback, scope) {
  for (let i = 0; i < array.length; i += 1) {
    callback.call(scope, i, array[i])
  }
}

export function $1(selector, context) {
  return (context || document).querySelector(selector)
}

export function $(selector, context) {
  return (context || document).querySelectorAll(selector)
}

export function whichTransitionEvent() {
  var t
  var el = document.createElement('fakeelement')
  var transitions = {
    'transition': 'transitionend',
    'OTransition': 'oTransitionEnd',
    'MozTransition': 'transitionend',
    'WebkitTransition': 'webkitTransitionEnd',
  }

  for(t in transitions) {
    if(el.style[t] !== undefined) {
      return transitions[t]
    }
  }
}

export function getClosest(elem, selector) {
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function(s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
            i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) {}
        return i > -1
      }
  }

  // Get the closest matching element
  for ( ; elem && elem !== document; elem = elem.parentNode ) {
    if ( elem.matches( selector ) ) return elem;
  }
  return null
}

export function insertAfter(newNode, referenceNode) {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

export function lettering(element, options) {
  options = options || {}

  const tagName = options.tagName || 'span'
  const classPrefix = options.classPrefix != null ? options.classPrefix : 'char'
  let count = 1

  function inject(element) {
    const parentNode = element.parentNode
    const string = element.nodeValue
    const length = string.length
    let i = -1
    while (++i < length) {
      const node = document.createElement(tagName)
      const emptyClass = string[i] === ' ' ? ' is-empty' : ''
      if (classPrefix) {
        node.className = classPrefix + count + emptyClass
        count++
      }
      node.appendChild(document.createTextNode(string[i]))
      parentNode.insertBefore(node, element)
    }
    parentNode.removeChild(element)
  }

  ;(function traverse(element) {
    // `element` is itself a text node.
    if (element.nodeType === Node.TEXT_NODE) {
      return inject(element)
    }

    // `element` has a single child text node.
    const childNodes = Array.prototype.slice.call(element.childNodes) // static array of nodes
    const length = childNodes.length
    if (length === 1 && childNodes[0].nodeType === Node.TEXT_NODE) {
      return inject(childNodes[0])
    }

    // `element` has more than one child node.
    let i = -1
    while (++i < length) {
      traverse(childNodes[i])
    }
  })(element)
}

export function truncate(string, length) {
  if (string.length > length) {
    return `${string.substring(0, length)}...`
  } else {
    return string
  }
}

export function isSafari() {
  return navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1
}

export function isMobileDevice() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
      return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
  }

  return "unknown";
}

export function consoleUtil() {
  console.info()
  console.info(`
  Credits:
  * http://ideonetwork.it/


  MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMhho
  MMMMMMMMMMMMMMMMMMMMMMMMMMMMhoooo
  MMMMMMMMMMMMMMMMMMMMMMMMMMMoooooo
  MMMMMMMMMMMMMMMMMMMMMMMMMhooooooo
  MMMMMMMMMMMMMMMMMMMMMMMhooooooooo
  MMMMMMMMMMMMMMMMMMMMMhooooooooooo
  MMMMMMMMMMMMMMMMMMMhooooooooooooo
  MMMMMMMMMMMMMMMMMMhoooooooooooooo
  MMMMMMMMMMMMMMMMhoooooooooooooooh
  MMMMMMMMMMMMMMMhooooooooooooooohM
  MMMMMMMMMMMMMhoooooooooooooooohMM
  MMMMMMMMMMMMhoooooooooooooooohMMM
  MMMMMMMMMMMooooooooooooooooohMMMM
  MMMMMMMMMhooooooooooooooooohMMMMM
  MMMMMMMMhoooooooooooooooooMMMMMMM
  MMMMMMMhoooooooooooooooohMMMMMMMM
  MMMMMMhoooooooooooooooohMMMMMMMMM
  MMMMMhooooooooooooooohMMMMMMMMMMM
  MMMMhoooooooooooooohMMMMMMMMMMMMM
  MMMhooooooooooooohMMMMMMMMMMMMMMM
  MMMooooooooooooohMMMMMMMMMMMMMMMM
  MMoooooooooooohMMMMMMMMMMMMMMMMMM
  MhoooooooooooMMMMMMMMMMMMMMMMMMMM
  MoooooooooohMMMMMMMMMMMMMMMMMMMMM
  MooooooooohMMMMMMMMMMMMMMMMMMMMMM
  hooooooooMMMMMMMMMMMMMMMMMMMMMMMM
  ooooooooMMMMMMMMMMMMMMMMMMMMMMMMM
  oooooooMMMMMMMMMMMMMMMMMMMMMMMMMM
  oooooohMMMMMMMMMMMMMMMMMMMMMMMMMM
  MoooooMMMMMMMMMMMMMMMMMMMMMMMMMMM
  MoooohMMMMMMMMMMMMMMMMMMMMMMMMMMM
  MhoooMMMMMMMMMMMMMMMMMMMMMMMMMMMM
  MMoooMMMMMMMMMMMMMMMMMMMMMMMMMMMM
  MMMooMMMMMMMMMMMMMMMMMMMMMMMMMMMM
  `)
}
