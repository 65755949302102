import TweenMax, { TimelineMax, Power3 } from 'gsap'
import axios from 'axios'
import { $, $1, each } from '../util'

const QuizManager = (() => {
  const buildQuiz = () => {
    const answersContainer = $1('.c-quiz__answers')
    const rightAnswer = $1('.c-quiz__right-answer')
    const errorAnswer = $1('.c-quiz__error-answer')
    const errorAnswerOverlay = $1('.c-quiz__error-answer-overlay')
    const errorAnswerTitle = $1('.c-quiz__error-answer-text')
    const errorAnswerCta = $1('.c-quiz_error-answer-final-cta')
    const realAnswer = $1('.c-quiz__reveal-answer')

    const submitAnswer = $1('.c-quiz__submit-answer')
    const answers = $('.c-quiz-answer')

    TweenMax.set(answersContainer, { opacity: 0, visibility: 'hidden', zIndex: -1 })
    TweenMax.set(rightAnswer, { opacity: 0, visibility: 'hidden', zIndex: -1 })
    TweenMax.set(errorAnswer, { opacity: 0, visibility: 'hidden', zIndex: -1 })
    TweenMax.set(errorAnswerOverlay, { y: '-100%' })
    TweenMax.set(errorAnswerTitle, { opacity: 0, y: '-51%', x: '-50%' })
    TweenMax.set(errorAnswerCta, { opacity: 0 })
    TweenMax.set(realAnswer, { opacity: 0, visibility: 'hidden', zIndex: -1 })
    TweenMax.set(submitAnswer, { opacity: 0, visibility: 'hidden', zIndex: -1, scale: 0 })
    TweenMax.set(answers, { opacity: 0, y: '-10px' })
  }

  const initQuestionAnim = () => {
    const questionCta = $1('.c-quiz__question-cta')
    const answerImg = $1('.c-quiz__answers-img-preview')
    const answersContainer = $1('.c-quiz__answers')
    const answers = $('.c-quiz-answer')

    const initQuestionTl = new TimelineMax()

    initQuestionTl
      .to(questionCta, 0.3, {
        opacity: 0,
        onComplete: () => {
          TweenMax.set(questionCta, { visibility: 'hidden', zIndex: -1 })
        },
      })
      .to(answerImg, 0.4, {
        opacity: 0,
        onComplete: () => {
          TweenMax.set(answerImg, { visibility: 'hidden', zIndex: -1 })
        },
      }, '-=0.3')
      .to(answersContainer, 0.1, {
        opacity: 1,
        visibility: 'visible',
        zIndex: 1,
      })
      .staggerTo(answers, 0.4, {
        opacity: 1,
        y: '0%',
      }, 0.1)
  }

  const selectAnswer = () => {
    const answers = $('.c-quiz-answer')
    const submitAnswer = $1('.c-quiz__submit-answer')

    each(answers, (i, answer) => {
      answer.addEventListener('click', function() {
        each(answers, (l, ans) => ans.classList.remove('is-active'))
        this.classList.add('is-active')

        if (!submitAnswer.classList.contains('is-active')) {
          TweenMax.set(submitAnswer, {
            visibility: 'visible',
            zIndex: 1,
            onComplete: () => {
              TweenMax.to(submitAnswer, 0.4, {
                scale: 1,
                opacity: 1,
                onComplete: () => {
                  submitAnswer.classList.add('is-active')
                },
              })
            },
          })
        }
      })
    })
  }

  const revealRightAnswer = () => {
    const rightAnswer = $1('.c-quiz__right-answer')
    const answersContainer = $1('.c-quiz__answers')
    const answers = $('.c-quiz-answer')
    const submitAnswer = $1('.c-quiz__submit-answer')

    const revealRightTl = new TimelineMax()

    revealRightTl
      .set(rightAnswer, {
        visibility: 'visible',
        zIndex: 1,
      })
      .to(submitAnswer, 0.3, {
        opacity: 0,
        scale: 0,
        onComplete: () => {
          TweenMax.set(submitAnswer, {
            visibility: 'hidden',
            zIndex: -1,
          })
        },
      })
      .staggerTo(answers, 0.4, {
        opacity: 0,
        y: '-10px',
      }, 0.1)
      .to(answersContainer, 0.2, {
        opacity: 0,
        onComplete: () => {
          TweenMax.set(answersContainer, {
            visibility: 'hidden',
            zIndex: -1,
          })
        },
      })
      .to(rightAnswer, 0.4, {
        opacity: 1,
      })
  }

  const revealError = () => {
    const errorAnswer = $1('.c-quiz__error-answer')
    const errorAnswerOverlay = $1('.c-quiz__error-answer-overlay')
    const errorAnswerTitle = $1('.c-quiz__error-answer-text')
    const errorAnswerCta = $1('.c-quiz_error-answer-final-cta')
    const submitAnswer = $1('.c-quiz__submit-answer')
    const answersContainer = $1('.c-quiz__answers')
    const answers = $('.c-quiz-answer')

    const revealErrorTl = new TimelineMax()

    revealErrorTl
      .to(submitAnswer, 0.3, {
        opacity: 0,
        scale: 0,
        onComplete: () => {
          TweenMax.set(submitAnswer, {
            visibility: 'hidden',
            zIndex: -1,
          })
        },
      })
      .staggerTo(answers, 0.4, {
        opacity: 0,
        y: '-10px',
      }, 0.1)
      .to(answersContainer, 0.2, {
        opacity: 0,
        onComplete: () => {
          TweenMax.set(answersContainer, {
            visibility: 'hidden',
            zIndex: -1,
          })
        },
      })
      .set(errorAnswer, {
        opacity: 1,
        visibility: 'visible',
        zIndex: 1,
      })
      .to(errorAnswerOverlay, 0.43, {
        y: '0%',
        ease: Power3.easeOut,
      })
      .to(errorAnswerTitle, 0.3, {
        opacity: 1,
        y: '-50%',
        x: '-50%',
      })
      .to(errorAnswerCta, 0.35, {
        opacity: 1,
      })
  }

  const revealRealAnswer = () => {
    const realAnswer = $1('.c-quiz__reveal-answer')
    const errorAnswer = $1('.c-quiz__error-answer')
    const errorAnswerOverlay = $1('.c-quiz__error-answer-overlay')
    const errorAnswerTitle = $1('.c-quiz__error-answer-text')
    const errorAnswerCta = $1('.c-quiz_error-answer-final-cta')

    const revealRealTl = new TimelineMax()

    revealRealTl
      .set(realAnswer, {
        visibility: 'visible',
        zIndex: 1,
      })
      .to(errorAnswerCta, 0.35, {
        opacity: 0,
      })
      .to(errorAnswerTitle, 0.3, {
        opacity: 0,
        y: '-53%',
        x: '-50%',
      })
      .to(errorAnswerOverlay, 0.43, {
        y: '-100%',
        ease: Power3.easeOut,
      })
      .to(errorAnswer, 0.3, {
        opacity: 0,
      })
      .set(errorAnswer, {
        visibility: 'hidden',
        zIndex: -1,
      })
      .to(realAnswer, 0.4, {
        opacity: 1,
      })
  }

  const onSubmitAnswer = () => {
    const activeAnswer = $1('.c-quiz-answer.is-active')
    const rightAnswer = $1('.c-quiz__right-answer')

    const activeAnswerId = parseInt(activeAnswer.dataset.answer, 10)
    const rightAnswerId = parseInt(rightAnswer.dataset.answer, 10)

    if (activeAnswerId === rightAnswerId) {
      revealRightAnswer()
    } else {
      revealError()
    }
  }

  const loadNextQuestion = (href) => {
    axios.get(href)
    .then((response) => {
      const quizContainer = $1('.js-quiz-container')
      const newquizContainer = document.createElement('div')
      newquizContainer.classList.add('js-quiz-container')
      newquizContainer.innerHTML = response.data
      quizContainer.parentNode.replaceChild(newquizContainer, quizContainer)
      QuizManager.init()
    })
    .catch((err) => console.log(err))
  }

  const init = () => {
    const quiz = $1('.js-quiz')

    if (quiz) {
      const initQuestion = $1('.js-init-question')
      const submitAnswer = $1('.c-quiz__submit-answer')
      const revealAnswer = $1('.js-reveal-answer')
      const nextQuestions = $('.c-quiz__right-answer-final-cta')

      buildQuiz()

      initQuestion.addEventListener('click', function() {
        $1('.c-quiz__answers-container').classList.add('visible')
        initQuestionAnim()
      })

      submitAnswer.addEventListener('click', function() {
        onSubmitAnswer()
      })

      revealAnswer.addEventListener('click', function() {
        revealRealAnswer()
      })

      each(nextQuestions, (i, button) => {
        button.addEventListener('click', function() {
          loadNextQuestion(button.dataset.href)
        })
      })

      selectAnswer()
    }
  }

  return {
    init,
  }
})()

export default QuizManager
