import { TimelineMax, TweenMax, Power3 } from 'gsap'
import axios from 'axios'
import { $, $1, each } from '../util'

function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const ContactsManager = (() => {
  let isAnimating = false
  let isSending = false
  let activeIndex = 0
  let whoValue = null
  let nameValue = null
  let emailValue = null
  let messageValue = null

  const openAnim = () => {
    const openTl = new TimelineMax()

    if (!isAnimating) {
      const contactsModal = $1('.c-contacts-modal')
      const contactsModalBg = $1('.c-contacts-modal__bg')
      const contactsModalBgLayer = $1('.c-contacts-modal__bg-layer')
      const contactsModalClose = $('.c-contacts-modal__close-container')
      const contactsModalContent = $1('.c-contacts-modal__content')

      isAnimating = true

      openTl
        .set(contactsModal, {
          zIndex: 9999999,
          visibility: 'visible',
          opacity: 1,
        })
        .to(contactsModalBgLayer, 0.6, {
          y: '-100%',
          ease: Power3.easeOut,
        })
        .to(contactsModalBg, 0.6, {
          y: '-100%',
          ease: Power3.easeOut,
        }, '-=0.3')
        .to(contactsModalContent, 0.6, {
          opacity: 1,
        }, '-=0.3')
        .to(contactsModalClose, 0.4, {
          opacity: 1,
          onComplete: () => {
            isAnimating = false
          },
        })
    }
  }

  const openContacts = () => {
    const opener = $('.js-open-contacts')

    each(opener, (i, open) => {
      open.addEventListener('click', () => {
        openAnim()
      })
    })
  }

  const resetForm = () => {
    const contactSteps = $('.c-contacts-modal__step')
    const contactMainSteps = $('.c-contacts-modal__main-step')
    const radios = $('.c-contacts-modal__radio')
    const goNext = $1('.js-step-next')

    goNext.classList.add('disabled')

    each(contactSteps, (i, ctStep) => {
      ctStep.classList.remove('is-active')
      if (i !== 0) {
        ctStep.setAttribute('disabled', true)
      }
    })

    each(contactMainSteps, (i, ctMainStep) => {
      ctMainStep.classList.remove('is-active')
    })

    contactSteps[0].classList.add('is-active')
    contactMainSteps[0].classList.add('is-active')

    $('.c-contacts-modal__main-step')[0].classList.add('is-active')
    $('.c-contacts-modal__step')[0].classList.add('is-active')

    each(radios, (i, radio) => {
      radio.checked = false
    })
  }

  const closeAnim = () => {
    const closeTl = new TimelineMax()

    if (!isAnimating) {
      const contactsModal = $1('.c-contacts-modal')
      const contactsModalBg = $1('.c-contacts-modal__bg')
      const contactsModalBgLayer = $1('.c-contacts-modal__bg-layer')
      const contactsModalClose = $('.c-contacts-modal__close-container')
      const contactsModalContent = $1('.c-contacts-modal__content')

      isAnimating = true

      closeTl
        .to(contactsModalClose, 0.4, {
          opacity: 0,
        })
        .to(contactsModalContent, 0.4, {
          opacity: 0,
        })
        .to(contactsModalBg, 0.6, {
          y: '-200%',
          ease: Power3.easeOut,
        })
        .to(contactsModalBgLayer, 0.6, {
          y: '-200%',
          ease: Power3.easeOut,
        }, '-=0.4')
        .set(contactsModal, {
          zIndex: -1,
          visibility: 'hidden',
          opacity: 0,
          onComplete: () => {
            isAnimating = false

            resetForm()

            TweenMax.set(contactsModalBg, { y: '0%' })
            TweenMax.set(contactsModalBgLayer, { y: '0%' })
          },
        })
    }
  }

  const closeContacts = () => {
    const closer = $('.js-close-contacts')

    each(closer, (i, close) => {
      close.addEventListener('click', () => {
        closeAnim()
      })
    })
  }

  const manageNextActive = (index) => {
    const goNext = $1('.js-step-next')
    const contactSteps = $('.c-contacts-modal__step')
    if (contactSteps[index + 1] && contactSteps[index + 1].hasAttribute('disabled')) {
      goNext.classList.add('disabled')
    } else {
      goNext.classList.remove('disabled')
    }
  }

  const stepControl = () => {
    const contactSteps = $('.c-contacts-modal__step')
    const radios = $('.c-contacts-modal__radio')
    const theName = document.getElementById('form-name')
    const theEmail = document.getElementById('form-email')
    const theMessage = document.getElementById('form-message')

    each(radios, (i, radio) => {
      radio.addEventListener('click', function() {
        each(radios, (l, r) => {
          if (r.checked) {
            contactSteps[1].removeAttribute('disabled')
            if (r.value !== 'privato') {
              $1('.js-name-label').innerHTML = "Qual è la ragione sociale?"
            } else {
              $1('.js-name-label').innerHTML = 'Come ti chiami?'
            }

            whoValue = radio.value
          }

          manageNextActive(0)
        })
      })
    })

    each(contactSteps, (i, contactStep) => {
      contactStep.addEventListener('click', function() {
        if (!this.hasAttribute('disabled')) {
          const stepNum = parseInt(this.dataset.step, 10)
          $1('.c-contacts-modal__step.is-active').classList.remove('is-active')
          this.classList.add('is-active')

          activeIndex = stepNum - 1

          $1('.c-contacts-modal__main-step.is-active').classList.remove('is-active')
          $1(`.c-contacts-modal__main-step[data-step="${stepNum}"]`).classList.add('is-active')
          manageNextActive(stepNum)
        }
      })
    })

    theName.addEventListener('keyup', function(e) {
      if (this.value !== '') {
        contactSteps[2].removeAttribute('disabled')
      } else {
        contactSteps[2].setAttribute('disabled', true)
        contactSteps[3].setAttribute('disabled', true)
      }

      nameValue = theName.value
      manageNextActive(1)
    })

    theEmail.addEventListener('keyup', function(e) {
      if (this.value !== '' && validateEmail(this.value)) {
        contactSteps[3].removeAttribute('disabled')
      } else {
        contactSteps[3].setAttribute('disabled', true)
      }

      emailValue = theEmail.value
      manageNextActive(2)
    })

    theMessage.addEventListener('keyup', function(e) {
      // if (this.value !== '') {
      //   contactSteps[3].removeAttribute('disabled')
      // } else {
      //   contactSteps[3].setAttribute('disabled', true)
      // }

      messageValue = theMessage.value
      // manageNextActive(3)
    })
  }

  const navigation = () => {
    const goNext = $1('.js-step-next')
    const goPrev = $1('.js-step-prev')
    const contactSteps = $('.c-contacts-modal__step')
    const contactMainSteps = $('.c-contacts-modal__main-step')
    const messageError = $1('.js-contacts-message-error')

    if (goNext && goPrev) {
      goNext.addEventListener('click', () => {
        if (activeIndex >= 0 && activeIndex < 3) {
          if (contactSteps[activeIndex + 1].hasAttribute('disabled')) return
          activeIndex += 1

          each(contactMainSteps, (i, ctM) => {
            ctM.classList.remove('is-active')
          })

          contactMainSteps[activeIndex].classList.add('is-active')
          contactSteps[activeIndex].classList.add('is-active')

          manageNextActive(activeIndex)
        } else {
          if (messageValue === '' || messageValue === null) {
            messageError.classList.add('is-message-error')
          } else {
            const form = $1('.js-contacts-form')
            const endpoint = form.dataset.endpoint
            const data = new FormData()

            messageError.classList.remove('is-message-error')

            data.append('who', whoValue)
            data.append('name', nameValue)
            data.append('email', emailValue)
            data.append('message', messageValue)

            const responseTitle = $1('.js-contact-response-title')
            const responseText = $1('.js-contact-response-text')
            const responseIcon = $1('.js-contact-response-icon')

            if (!isSending) {
              isSending = true

              axios({
                method: 'post',
                url: endpoint,
                data,
              })
              .then((response) => {
                isSending = false

                document.body.classList.add('is-contacts-response')

                if (response.data.result) {
                  responseTitle.innerText = 'Messaggio inviato correttamente.'
                  responseText.innerText = 'Verrai ricontatto al più presto.'

                  responseIcon.classList.add('is-success')
                  responseIcon.classList.remove('is-error')

                  setTimeout(() => {
                    setTimeout(() => {
                      closeAnim()
                    }, 500)

                    document.body.classList.remove('is-contacts-response')
                  }, 2500)
                } else {
                  responseTitle.innerText = 'Errore'
                  responseText.innerText = response.data.error

                  responseIcon.classList.add('is-error')
                  responseIcon.classList.remove('is-success')
                }
              })
              .catch((err) => {
                isSending = false
                console.log(err)

                responseTitle.innerText = 'Errore di connessione.'
                responseText.innerText = 'Riprova più tardi.'

                responseIcon.classList.add('is-error')
                responseIcon.classList.remove('is-success')
              })
            }
          }
        }
      })

      goPrev.addEventListener('click', () => {
        if (activeIndex > 0 && activeIndex < 4) {
          activeIndex -= 1

          each(contactMainSteps, (i, ctM) => {
            ctM.classList.remove('is-active')
          })

          contactMainSteps[activeIndex].classList.add('is-active')
          contactSteps[activeIndex].classList.add('is-active')

          manageNextActive(activeIndex)
        }
      })
    }
  }

  const closeResponse = () => {
    const closer = $('.js-close-response')

    each(closer, (i, close) => {
      close.addEventListener('click', () => {
        document.body.classList.remove('is-contacts-response')
      })
    })
  }

  const setDimensions = () => {
    const blocks = $('.c-contacts-box')

    each(blocks, (i, block) => {
      const w = block.getBoundingClientRect().width
      block.style.height = `${w}px`
    })
  }

  const init = () => {
    openContacts()
    closeResponse()
    closeContacts()
    stepControl()
    navigation()
    setDimensions()
  }

  return {
    init,
    setDimensions,
  }
})()

export default ContactsManager
