import Barba from 'barba.js'
import CarouselManager from './CarouselManager'
import NavbarManager from './NavbarManager'
import Tabs from './Tabs'
import SocialWall from './SocialWall'
import Timeline from './Timeline'
import Organization from './Organization'
import Assistance from './Assistance'
import PlaylistManager from './PlaylistManager'
import ContactsManager from './ContactsManager'
import Curiosities from './Curiosities'
import QuizManager from './QuizManager'
import FullscreenVideo from './FullscreenVideo'
import JobRequests from './JobRequests'
import CookieManager from './CookieManager'
import AnimationManager from './AnimationManager'
import NewsletterManager from './NewsletterManager'
import EnergySaving from './EnergySaving'

const RouterManager = (() => {
  const initTransition = function initTransition() {
    let destination
    Barba.Dispatcher.on('linkClicked', (el) => {
      destination = el.dataset.destination
    })

    const MainTransition = Barba.BaseTransition.extend({
      start: function start() {
        Promise
        .all([this.newContainerLoading, this.exit()])
        .then(this.finish.bind(this))
      },

      exit: function fadeOut() {
        const deferred = Barba.Utils.deferred()

        AnimationManager.exitPage(() => {
          deferred.resolve()
        })

        return deferred.promise
      },

      finish: function finish() {
        window.scroll(0, 0)
        document.body.scrollTop = 0

        this.done()

        setTimeout(() => {
          AnimationManager.enterPage()
        }, 1000)
      },
    })

    Barba.Pjax.getTransition = () => MainTransition
  }

  const reInit = () => {
    CarouselManager.init()
    NavbarManager.init()
    SocialWall.init()
    Assistance.init()
    Organization.init()
    Timeline.init()
    Curiosities.init()
    Tabs.init()
    ContactsManager.init()
    JobRequests.init()
    CookieManager.init()
    NewsletterManager.init()
    PlaylistManager.init()
    FullscreenVideo.init()
    QuizManager.init()
    AnimationManager.init()
    EnergySaving.init()
  }

  const initRouter = () => {
    Barba.Pjax.Dom.containerClass = 'l-site-container'
    Barba.Pjax.Dom.wrapperId = 'page-wrap'
    Barba.Pjax.ignoreClassLink = 'no-barba'

    Barba.Pjax.start()
    Barba.Prefetch.init()

    initTransition()

    Barba.Dispatcher.on('transitionCompleted', () => {
      reInit()
    })
  }

  const init = () => {
    initRouter()
  }

  return {
    init,
  }
})()

export default RouterManager
