import YouTubePlayer from 'youtube-player'
import { $1 } from '../util';

const FullscreenVideo = (() => {
  let player

  const videoScale = () => {
    const frame = document.getElementById('video')

    let w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    let h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

    w += 200
    h += 200

    if (w / h > 16 / 9) {
      player.setSize(w, w/16*9)
      frame.style.left = '0px'
    } else {
      player.setSize(h/9*16, h)
      frame.style.left = `${-(frame.offsetWidth-w)/2}`
    }
  }

  const buildVideo = () => {
    const frameInit = document.getElementById('video')

    if (frameInit) {
      const videoId = frameInit.dataset.videoId

      player = YouTubePlayer('video', {
        rel: 0,
      })
      player.loadVideoById(videoId, 0, 'large')
      player.mute()

      videoScale()

      player.on('stateChange', (event) => {
        if (event.data === 0) {
          player.playVideo()
        }
      })

      player.playVideo()
    }
  }

  const init = () => {
    buildVideo()
  }

  return {
    init,
    scaleVideo: videoScale,
  }
})()

export default FullscreenVideo
