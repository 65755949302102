import { TweenMax, Quad, Elastic, TimelineMax, Power3 } from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import HoverButton from './HoverButton'
// import NavbarManager from './NavbarManager'
import { $, $1, each, isSafari } from '../util'

const AnimationManager = (() => {
  const initTilt = () => {
    const parallaxBlocks = $('.js-parallax-block')
    const blockCta = $('.c-box-cta')

    each(parallaxBlocks, (i, block) => {
      block.addEventListener('mousemove', (e) => {
        const decimalX = (e.clientX / window.innerWidth) - 0.5
        const decimalY = (e.clientY / window.innerHeight) - 0.5

        TweenMax.to(block, 0.3, {
          rotationY: 20 * decimalX,
          rotationX: 20 * decimalY,
          ease: Quad.easeOut,
          transformPerspective: 1000,
          transformOrigin: 'center',
        })
      })
    })

    each(blockCta, (i, ctaBlock) => {
      const square = ctaBlock.querySelector('.c-box-cta__square')
      const text = ctaBlock.querySelector('.c-box-cta__text')

      ctaBlock.addEventListener('mousemove', (e) => {
        const decimalX = (e.clientX / window.innerWidth) - 0.5
        const decimalY = (e.clientY / window.innerHeight) - 0.5

        TweenMax.to(square, 0.3, {
          rotationY: 20 * decimalX,
          rotationX: 20 * decimalY,
          ease: Quad.easeOut,
          transformPerspective: 1000,
          transformOrigin: 'center',
        })

        TweenMax.to(text, 0.3, {
          rotationY: -(20 * decimalX),
          rotationX: -(20 * decimalY),
          ease: Quad.easeOut,
          transformPerspective: 1000,
          transformOrigin: 'center',
        })
      })
    })
  }

  const parallaxMainBg = () => {
    const sections = $('.js-section-main-parallax')

    each(sections, (i, section) => {
      const bg = section.querySelectorAll('.js-main-parallax-bg')

      section.addEventListener('mousemove', (e) => {
        const decimalX = (e.clientX / window.innerWidth) - 0.5
        const decimalY = (e.clientY / window.innerHeight) - 0.5

        TweenMax.to(bg, 0.3, {
          rotationY: 20 * decimalX,
          rotationX: 20 * decimalY,
          ease: Quad.easeOut,
          transformPerspective: 1000,
          transformOrigin: 'center',
        })
      })

      section.addEventListener('mouseleave', () => {
        TweenMax.to(bg, 0.8, {
          rotationY: 0,
          rotationX: 0,
          ease: Elastic.easeOut.config(1.2, 0.4),
        })
      })
    })
  }

  const assistanceBlockParallax = () => {
    const assistanceBlock = $1('.o-assistance-block__phone-block')

    if (assistanceBlock) {
      assistanceBlock.addEventListener('mousemove', function(e) {
        const decimalX = (e.clientX / window.innerWidth) - 0.5
        const decimalY = (e.clientY / window.innerHeight) - 0.5

        TweenMax.to(this, 0.3, {
          rotationY: 20 * decimalX,
          rotationX: 20 * decimalY,
          ease: Quad.easeOut,
          transformPerspective: 1000,
          transformOrigin: 'center',
        })
      })

      assistanceBlock.addEventListener('mouseleave', function() {
        TweenMax.to(this, 0.7, {
          rotationY: 0,
          rotationX: 0,
          ease: Elastic.easeOut.config(1.2, 0.4),
        })
      })
    }
  }

  const smallCoffeeParallax = () => {
    const sections = $('.js-small-coffee-parallax')

    each(sections, (i, section) => {
      const firstChicco = section.querySelectorAll('.o-small-coffee-ill__first-el')
      const secondChicco = section.querySelectorAll('.o-small-coffee-ill__second-el')
      const thirdChicco = section.querySelectorAll('.o-small-coffee-ill__third-el')

      section.addEventListener('mousemove', (e) => {
        const decimalX = (e.clientX / window.innerWidth) - 0.5
        const decimalY = (e.clientY / window.innerHeight) - 0.5

        TweenMax.to(firstChicco, 0.3, {
          x: 20 * decimalX,
          y: 20 * decimalY,
          ease: Quad.easeOut,
          transformOrigin: 'center',
        })

        TweenMax.to(secondChicco, 0.3, {
          y: 20 * decimalX,
          x: -(20 * decimalY),
          ease: Quad.easeOut,
          transformOrigin: 'center',
        })

        TweenMax.to(thirdChicco, 0.3, {
          y: 20 * decimalX,
          x: 20 * decimalY,
          ease: Quad.easeOut,
          transformOrigin: 'center',
        })
      })
    })
  }

  const homeCoffeeParallax = () => {
    const sections = $('.js-home-coffee-parallax')

    each(sections, (i, section) => {
      const firstChicco = section.querySelector('.o-home-coffee__first-el')
      const secondChicco = section.querySelector('.o-home-coffee__second-el')
      const thirdChicco = section.querySelector('.o-home-coffee__third-el')
      const fourthChicco = section.querySelector('.o-home-coffee__fourth-el')

      section.addEventListener('mousemove', (e) => {
        const decimalX = (e.clientX / window.innerWidth) - 0.5
        const decimalY = (e.clientY / window.innerHeight) - 0.5

        TweenMax.to(firstChicco, 0.3, {
          x: 20 * decimalX,
          y: 20 * decimalY,
          ease: Quad.easeOut,
          transformOrigin: 'center',
        })

        TweenMax.to(secondChicco, 0.3, {
          y: 20 * decimalX,
          x: -(20 * decimalY),
          ease: Quad.easeOut,
          transformOrigin: 'center',
        })

        TweenMax.to(thirdChicco, 0.3, {
          y: 20 * decimalX,
          x: 20 * decimalY,
          ease: Quad.easeOut,
          transformOrigin: 'center',
        })

        TweenMax.to(fourthChicco, 0.3, {
          x: -(20 * decimalX),
          y: 20 * decimalY,
          ease: Quad.easeOut,
          transformOrigin: 'center',
        })
      })
    })
  }

  const homeProductCtaParallax = () => {
    const sections = $('.js-product-home-parallax')

    each(sections, (i, section) => {
      const firstLeaf = section.querySelector('.o-home-product-cta__first-leaf')
      const secondLeaf = section.querySelector('.o-home-product-cta__second-leaf')
      const lemon = section.querySelector('.o-home-product-cta__lemon')
      const strawberry = section.querySelector('.o-home-product-cta__strawberry')
      const firstMore = section.querySelector('.o-home-product-cta__first-more ')
      const secondMore = section.querySelector('.o-home-product-cta__second-more ')

      section.addEventListener('mousemove', (e) => {
        const decimalX = (e.clientX / window.innerWidth) - 0.5
        const decimalY = (e.clientY / window.innerHeight) - 0.5

        TweenMax.to(firstLeaf, 0.3, {
          x: 20 * decimalX,
          y: 20 * decimalY,
          ease: Quad.easeOut,
          transformOrigin: 'center',
          rotation: -50,
        })

        TweenMax.to(secondLeaf, 0.3, {
          x: 20 * decimalX,
          y: -(20 * decimalY),
          ease: Quad.easeOut,
          transformOrigin: 'center',
        })

        TweenMax.to(lemon, 0.3, {
          y: -(20 * decimalX),
          x: 20 * decimalY,
          ease: Quad.easeOut,
          transformOrigin: 'center',
        })

        TweenMax.to(strawberry, 0.3, {
          y: -(20 * decimalX),
          x: -(20 * decimalY),
          ease: Quad.easeOut,
          transformOrigin: 'center',
        })

        TweenMax.to(firstMore, 0.3, {
          x: 20 * decimalX,
          y: -(20 * decimalY),
          ease: Quad.easeOut,
          transformOrigin: 'center',
        })

        TweenMax.to(secondMore, 0.3, {
          x: -(20 * decimalX),
          y: (20 * decimalY),
          ease: Quad.easeOut,
          transformOrigin: 'center',
        })
      })
    })
  }

  const gsbParallax = () => {
    const sections = $('.js-gsb-parallax')

    each(sections, (i, section) => {
      const bg = section.querySelector('.c-gsb__image-block')

      section.addEventListener('mousemove', (e) => {
        const decimalX = (e.clientX / window.innerWidth) - 0.5
        const decimalY = (e.clientY / window.innerHeight) - 0.5

        TweenMax.to(bg, 0.3, {
          rotationY: 20 * decimalX,
          rotationX: 20 * decimalY,
          ease: Quad.easeOut,
          transformPerspective: 1000,
          transformOrigin: 'center',
        })
      })
    })
  }

  const loadAnimation = () => {
    const pageWrap = document.getElementById('page-wrap')
    const loader = $1('.c-loader')
    const loaderContent = $1('.c-loader__content')
    const loadContent = $1('.js-load-content')
    const overlay = $1('.c-loader__overlay')

    TweenMax.set(pageWrap, {
      opacity: 0,
    })

    const loadTl = new TimelineMax()

    setTimeout(() => {
      loadTl
      .to(loaderContent, 0.4, {
        opacity: 0,
      })
      .to(loadContent, 0.4, {
        scale: 0,
        opacity: 0,
      }, '-=0.3')
      .to(overlay, 0.4, {
        x: '100%',
      })
      .to(overlay, 0.4, {
        x: '200%',
      })
      .set(loader, {
        zIndex: -1,
        visibility: 'hidden',
      })
      .to(pageWrap, 0.8, {
        opacity: 1,
      })
    }, 1100)
  }

  const manageProjectBlocks = () => {
    const projectBlocks = $('.c-project-block')

    each(projectBlocks, (i, projectBlock) => {
      const image = projectBlock.querySelector('.c-project-block__image')

      if (image) {
        const imageW = image.getBoundingClientRect().width
        image.style.height = `${imageW}px`
      }
    })
  }

  const initSmoothScroll = () => {
    const smoothLinks = $('[data-scroll]')

    each(smoothLinks, (i, smoothLink) => {
      smoothLink.addEventListener('click', function(e) {
        e.preventDefault()

        const id = this.getAttribute('href')
        TweenMax.to(window, 0.5, { scrollTo: { y: id, offsetY: 90 } })
      })
    })
  }

  const exitPage = (cb) => {
    const pageTransition = $1('.c-page-transition')
    const ptLogo = $1('.c-page-transition__logo')
    const ptLogoOverlay = $1('.c-page-transition__logo-overlay')

    TweenMax.set(pageTransition, { zIndex: '9999' })

    const exitTl = new TimelineMax()
    exitTl
      .to(pageTransition, 0.4, {
        y: '-100%',
        ease: Power3.easeOut,
        onComplete: () => {
          cb.call()

          document.body.classList.remove('is-mobile-menu-open')
          document.body.classList.remove('is-sub-mobile-open')
        },
      })
      .to(ptLogoOverlay, 0.7, {
        y: '-100%',
        ease: Power3.easeOut,
      })
      .to(ptLogo, 0.4, {
        scale: 1.1,
        ease: Power3.easeOut,
        onComplete: () => {
          TweenMax.set(ptLogoOverlay, { y: '100%' })
          cb.call()
        },
      }, '-=0.25')
  }

  const enterPage = () => {
    const pageTransition = $1('.c-page-transition')
    const ptLogo = $1('.c-page-transition__logo')
    const ptLogoOverlay = $1('.c-page-transition__logo-overlay')

    const enterTl = new TimelineMax()

    enterTl
      .to(ptLogo, 0.4, {
        scale: 1,
        ease: Power3.easeOut,
      })
      .to(ptLogoOverlay, 0.7, {
        y: '0%',
        ease: Power3.easeOut,
      }, '-=0.25')
      .to(pageTransition, 0.4, {
        y: '-200%',
        onComplete: () => {
          TweenMax.set(pageTransition, { y: '0%', zIndex: '-1' })
          TweenMax.set(ptLogoOverlay, { y: '0%' })
        },
      }, '+=0.25')
  }

  const togglePrivacy = () => {
    const opener = $1('.js-open-privacy')
    const closer = $('.js-close-privacy')

    if (opener) {
      opener.addEventListener('click', () => {
        document.body.classList.add('is-policy-open')
      })
    }

    each(closer, (i, cl) => {
      cl.addEventListener('click', () => {
        document.body.classList.remove('is-policy-open')
      })
    })
  }

  const init = () => {
    const parallaxBtns = $('.js-parallax-button')
    const orgModalClose = $1('.c-org-modal__close')
    const playlistClose = $1('.c-playlist-modal__close')
    const assistanceClose = $1('.c-assistance-modal__close-container')
    const curiosities = $('.o-curiosity-point')

    if (!isSafari()) {
      initTilt()
      parallaxMainBg()
      gsbParallax()
      assistanceBlockParallax()
    }

    togglePrivacy()

    homeProductCtaParallax()
    homeCoffeeParallax()
    manageProjectBlocks()
    initSmoothScroll()
    smallCoffeeParallax()

    each(curiosities, (i, curiosity) => {
      new HoverButton(curiosity)
    })

    each(parallaxBtns, (i, btn) => {
      new HoverButton(btn)
    })

    if (orgModalClose) {
      new HoverButton(orgModalClose)
    }

    if (playlistClose) {
      new HoverButton(playlistClose)
    }

    if (assistanceClose) {
      new HoverButton(assistanceClose)
    }
  }

  return {
    init,
    loadAnimation,
    manageProjectBlocks,
    exitPage,
    enterPage,
  }
})()

export default AnimationManager
