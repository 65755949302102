import axios from 'axios'
import { $1 } from '../util'

function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const NewsletterManager = (() => {
  const init = () => {
    const form = $1('.js-newsletter-form')
    const input = $1('.js-newsletter-input')
    const check = $1('.js-newsletter-check')
    const submit = $1('.c-newsletter-form__submit')
    const errorMessage = $1('.js-newsletter-form-error')
    const successOverlay = $1('.js-newsletter-form-overlay-success')
    if(!input) return
      input.addEventListener('keyup', function() {
        if (input.value && input.value !== '' && check.checked && validateEmail(input.value)) {
          submit.disabled = false
        } else {
          submit.disabled = true
        }
      })
    

    check.addEventListener('click', function() {
      if (input.value && input.value !== '' && check.checked && validateEmail(input.value)) {
        submit.disabled = false
      } else {
        submit.disabled = true
      }
    })

    if (form) {
      form.addEventListener('submit', (e) => {
        e.preventDefault()

        if (input.value && input.value !== '' && check.checked && validateEmail(input.value)) {
          const endpoint = form.dataset.endpoint
          const data = new FormData()
          data.append('email', input.value)

          form.classList.add('is-loading')

          axios({
            method: 'post',
            url: endpoint,
            data,
          })
          .then((response) => {
            form.classList.remove('is-loading')
            errorMessage.classList.remove('is-error')
            errorMessage.innerText = ''
            successOverlay.classList.remove('is-success')

            if (response.data.result) {
              successOverlay.classList.add('is-success')

              setTimeout(() => {
                successOverlay.classList.remove('is-success')
              }, 2000)
            } else {
              console.log(response.data.error)
              errorMessage.classList.add('is-error')
              errorMessage.innerText = response.data.error
            }
          })
          .catch(err => console.log(err))
        }
      })
    }
  }

  return {
    init,
  }
})()

export default NewsletterManager
