import { TimelineMax, Power3, Elastic } from 'gsap'
import { $, $1, each } from '../util'

const Organization = (() => {
  let isAnimating = false

  const openAnim = () => {
    const openTl = new TimelineMax()

    if (!isAnimating) {
      const modal = $1('.c-org-modal')
      const layer = $('.c-org-modal__layer')
      const modalClose = $1('.c-org-modal__close-container')
      const bubbles = $('.js-org-bubble')
      const lastBubble = $1('.js-last-bubble')

      isAnimating = true

      openTl
        .set(modal, {
          visibility: 'visible',
          zIndex: 999,
        })
        .staggerTo(layer, 0.6, {
          x: '100%',
          ease: Power3.easeIn,
        }, 0.2)
        .to(modalClose, 0.5, {
          opacity: 1,
          ease: Power3.easeIn,
        })
        .staggerTo(bubbles, 1, {
          scale: 1,
          opacity: 1,
          ease: Elastic.easeOut,
        }, 0.04)
        .to(lastBubble, 0.8, {
          scale: 1,
          opacity: 1,
          ease: Elastic.easeOut,
          onComplete: () => {
            isAnimating = false

            each(bubbles, (i, bubble) => {
              bubble.classList.add('is-ready')
            })
          },
        }, '-=0.5')
    }
  }

  const openOrg = () => {
    const opener = $('.js-open-org')

    each(opener, (i, open) => {
      open.addEventListener('click', () => {
        openAnim()
      })
    })
  }

  const closeAnim = () => {
    const closeTl = new TimelineMax()

    if (!isAnimating) {
      const modal = $1('.c-org-modal')
      const layer = $('.c-org-modal__layer')
      const modalClose = $1('.c-org-modal__close-container')
      const bubbles = $('.js-org-bubble')
      const lastBubble = $1('.js-last-bubble')

      isAnimating = true

      each(bubbles, (i, bubble) => {
        bubble.classList.remove('is-ready')
      })

      closeTl
        .to(lastBubble, 0.6, {
          scale: 0,
          opacity: 0,
          ease: Elastic.easeIn,
        })
        .staggerTo(bubbles, 1.2, {
          scale: 0,
          opacity: 0,
          ease: Elastic.easeIn,
        }, 0.05, '-=0.6')
        .to(modalClose, 0.5, {
          opacity: 0,
          ease: Power3.easeIn,
        }, '-=0.8')
        .staggerTo(layer, 0.6, {
          x: '200%',
          ease: Power3.easeIn,
          onComplete: () => {
            isAnimating = false

            each(bubbles, (i, bubble) => {
              bubble.classList.remove('is-open')
            })
          },
        }, 0.2, '-=0.4')
        .set(modal, {
          visibility: 'hidden',
          zIndex: -1,
        })
        .set(layer, {
          x: '0%',
        })
    }
  }

  const closeOrg = () => {
    const closer = $('.js-close-org')

    each(closer, (i, close) => {
      close.addEventListener('click', () => {
        closeAnim()
      })
    })
  }

  const setBubbles = () => {
    const bubbles = $('.c-org-bubble')
    // const container = $1('.c-org-modal__container')
    // const width = container.clientWidth - (container.clientWidth / 5)
    // const height = container.clientHeight

    // each(bubbles, (i, bubble) => {
    //   const dimension = Math.floor(Math.random() * (180 - 90 + 1) + 90)

    //   bubble.style.width = `${dimension}px`
    //   bubble.style.height = `${dimension}px`
    // })
  }

  const bubbleEvents = () => {
    const bubbles = $('.js-org-bubble')

    each(bubbles, (i, bubble) => {
      bubble.addEventListener('click', function() {
        if (this.classList.contains('is-open')) {
          this.classList.remove('is-open')
          each(bubbles, (l, bb) => bb.classList.remove('is-open'))
        } else {
          each(bubbles, (l, bb) => bb.classList.remove('is-open'))
          this.classList.add('is-open')
        }
      })
    })
  }

  const init = () => {
    openOrg()
    closeOrg()

    setBubbles()

    bubbleEvents()
  }

  return {
    init,
  }
})()

export default Organization
