import { TimelineMax, Power3, TweenMax } from 'gsap'
import { $1, $, each } from '../util'

const NavbarManager = (() => {
  // let isAnimating = false

  const hide = () => {
    const links = $('.c-nav-menu__link')
    // const address = $1('.c-nav-menu__side-address')
    // const sideLinks = $('.c-nav-menu__side-link')
    // const socialLinks = $('.c-nav-menu__side-social-link')
    const closeContainer = $1('.c-nav-menu__close-container')

    TweenMax.set(links, {
      y: '100%',
    })

    // TweenMax.set(address, {
    //   x: '-10px',
    //   opacity: 0,
    // })

    TweenMax.set(closeContainer, {
      opacity: 0,
    })

    // TweenMax.set(sideLinks, {
    //   x: '-10px',
    //   opacity: 0,
    // })

    // TweenMax.set(socialLinks, {
    //   y: '120%',
    // })
  }

  // const openMenuAnim = () => {
  //   const navMenu = $1('.c-nav-menu')
  //   const bg = $1('.c-nav-menu__bg')
  //   const image = $1('.c-nav-menu__image')
  //   const listItem = $('.c-nav-menu__item')
  //   const links = $('.c-nav-menu__link')
  //   const address = $1('.c-nav-menu__side-address')
  //   const sideLinks = $('.c-nav-menu__side-link')
  //   const socialLinks = $('.c-nav-menu__side-social-link')
  //   const closeContainer = $1('.c-nav-menu__close-container')
  //   const openMenuTl = new TimelineMax()

  //   navMenu.classList.add('is-open')

  //   if (!isAnimating) {
  //     isAnimating = true

  //     openMenuTl
  //       .set(navMenu, {
  //         visibility: 'visible',
  //         zIndex: '999',
  //       })
  //       .to(bg, 0.6, {
  //         y: '-100%',
  //         ease: Power3.easeInOut,
  //       })
  //       .staggerTo(links, 0.65, {
  //         y: '0%',
  //         ease: Power3.easeInOut,
  //         onComplete: () => {
  //           each(listItem, (i, li) => {
  //             li.style.overflow = 'visible'
  //           })
  //         },
  //       }, 0.045, '-=0.45')
  //       .to(bg, 0.5, {
  //         opacity: 0.95,
  //       }, '-=0.6')
  //       .to(image, 0.5, {
  //         opacity: 1,
  //       }, '-=0.5')
  //       .to(address, 0.5, {
  //         x: '0',
  //         opacity: 1,
  //         ease: Power3.easeInOut,
  //       }, '-=0.5')
  //       .staggerTo(sideLinks, 0.5, {
  //         x: '0',
  //         opacity: 1,
  //         ease: Power3.easeInOut,
  //       }, 0.035, '-=0.4')
  //       .staggerTo(socialLinks, 0.5, {
  //         y: '0%',
  //         ease: Power3.easeInOut,
  //       }, 0.035, '-=0.5')
  //       .to(closeContainer, 0.5, {
  //         opacity: 1,
  //         ease: Power3.easeInOut,
  //         onComplete: () => {
  //           closeContainer.removeAttribute('style')

  //           isAnimating = false
  //         },
  //       }, '-=0.3')
  //   }
  // }

  // const closeMenuAnim = (cb) => {
  //   const navMenu = $1('.c-nav-menu')
  //   const bg = $1('.c-nav-menu__bg')
  //   const image = $1('.c-nav-menu__image')
  //   const listItem = $('.c-nav-menu__item')
  //   const links = $('.c-nav-menu__link')
  //   const address = $1('.c-nav-menu__side-address')
  //   const sideLinks = $('.c-nav-menu__side-link')
  //   const socialLinks = $('.c-nav-menu__side-social-link')
  //   const closeContainer = $1('.c-nav-menu__close-container')
  //   const closeMenuTl = new TimelineMax()

  //   navMenu.classList.remove('is-open')

  //   if (!isAnimating) {
  //     isAnimating = true

  //     closeMenuTl
  //         .set(listItem, {
  //           overflow: 'hidden',
  //         })
  //         .to(closeContainer, 0.5, {
  //           opacity: 0,
  //           ease: Power3.easeInOut,
  //         })
  //         .staggerTo(socialLinks, 0.5, {
  //           y: '100%',
  //           ease: Power3.easeInOut,
  //         }, 0.035, '-=0.5')
  //         .staggerTo(sideLinks, 0.5, {
  //           x: '-10px',
  //           opacity: 0,
  //           ease: Power3.easeInOut,
  //         }, 0.035, '-=0.4')
  //         .to(address, 0.5, {
  //           x: '-10px',
  //           opacity: 0,
  //           ease: Power3.easeInOut,
  //         }, '-=0.5')
  //         .staggerTo(links, 0.65, {
  //           y: '-100%',
  //           ease: Power3.easeInOut,
  //         }, 0.045, '-=0.45')
  //         .to(image, 0.2, {
  //           opacity: 0,
  //         }, '-=0.5')
  //         .to(bg, 0.5, {
  //           opacity: 1,
  //           y: '-200%',
  //           ease: Power3.easeInOut,
  //           onComplete: () => {
  //             TweenMax.set(bg, {
  //               y: '0%',
  //             })
  //           },
  //         }, '-=0.5')
  //         .set(navMenu, {
  //           visibility: 'hidden',
  //           zIndex: '-1',
  //           onComplete: () => {
  //             isAnimating = false
  //             if (cb) {
  //               cb.call()
  //             }
  //           },
  //         })
  //   }
  // }

  const openMenu = () => {
    const opener = $1('.js-nav-toggle')

    if (opener) {
      opener.addEventListener('click', () => {
        document.body.classList.add('is-mobile-menu-open')
        document.querySelector('.c-nav-mobile').style.zIndex = '1000'
      })
    }
  }

  const closeMenu = () => {
    const closer = $1('.js-nav-close')

    if (closer) {
      closer.addEventListener('click', () => {
        document.body.classList.remove('is-mobile-menu-open')
        document.body.classList.remove('is-sub-mobile-open')
        setTimeout(() => {
          document.querySelector('.c-nav-mobile').style.zIndex = '-1'
        }, 500)
      })
    }
  }

  const toggleSubMobile = () => {
    const toggler = $1('.js-nav-mobile-reveal-sub')
    const closer = $1('.js-nav-mobile-close-sub')

    if (toggler) {
      toggler.addEventListener('click', function() {
        document.body.classList.add('is-sub-mobile-open')
      })
    }

    if (closer) {
      closer.addEventListener('click', function() {
        document.body.classList.remove('is-sub-mobile-open')
      })
    }
  }

  const onMenuScroll = () => {
    const nav = $1('.js-nav')
    const subnav = $1('.js-subnav')
    const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

    window.addEventListener('scroll', (e) => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop

      if (scrollTop > 0) {
        nav.classList.add('is-scroll')
        if (subnav) {
          subnav.classList.add('is-scroll')
        }
      } else {
        nav.classList.remove('is-scroll')
        if (subnav) {
          subnav.classList.remove('is-scroll')
        }
      }
    })
  }

  const init = () => {
    // hide()
    openMenu()
    closeMenu()
    toggleSubMobile()
    onMenuScroll()
  }

  return {
    init,
  }
})()

export default NavbarManager
