import Cookies from 'js-cookie'
import { $1 } from '../util'

const CookieManager = (() => {
  const accept = () => {
    const accepter = $1('.js-accept-cookies')

    if (accepter) {
      accepter.addEventListener('click', () => {
        Cookies.set('accept', true)
        document.body.classList.add('is-cookie-accept')
      })
    }
  }

  const showBar = () => {
    const isAccepted = Cookies.get('accept')

    if (isAccepted !== undefined && isAccepted) {
      document.body.classList.add('is-cookie-accept')
    } else {
      document.body.classList.remove('is-cookie-accept')
    }
  }

  const init = () => {
    accept()
    showBar()
  }

  return {
    init,
  }
})()

export default CookieManager
